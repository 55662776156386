/* ---------------------------------------------------------------------------------------------------- *\
    CONTENTS

    #   GENERAL SETUP - HELPER METHODS, CHECK READY STATE, PAGE LOAD
    #   OBJECT FIT POLYFILL
    #   SITE NAVIGATION
    #   ANCHOR SCROLL LINKS

\* ---------------------------------------------------------------------------------------------------- */



/* ---------------------------------------------------------------------------------------------------- *\
    GENERAL SETUP - HELPER METHODS, CHECK READY STATE, PAGE LOAD
\* ---------------------------------------------------------------------------------------------------- */

/* -------------------------------------------------- Element extensions */
Element.prototype.addClass = function(classname) {
	if (this.classList) {
		this.classList.add(classname);
	} else {
		this.className += ' ' + classname;
	}

	return this;
};

Element.prototype.removeClass = function(classname) {
	if (this.classList) {
		this.classList.remove(classname);
	} else {
		this.className = this.className.replace(new RegExp('(^|\\b)' + classname.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	}

	return this;
};

Element.prototype.hasClass = function(classname) {
	if (this.classList) {
		return this.classList.contains(classname);
	} else {
		return new RegExp('(^| )' + classname + '( |$)', 'gi').test(this.classname);
	}

	return this;
};

Element.prototype.toggleClass = function(classname) {
	if (this.hasClass(classname)) {
		this.removeClass(classname);
	} else {
		this.addClass(classname);
	}
}

Element.prototype.outerWidth = function() {
	var width = this.offsetWidth;
	var style = getComputedStyle(this);

	width += parseInt(style.marginLeft) + parseInt(style.marginRight);

	return width;
}

Element.prototype.closest = function(selector) {
	var theparent = this.parentNode;
	var find = document.querySelectorAll(selector);

	if (theparent != document.body) {
		var found = false;

		for (var a = 0; a < find.length; a++) {
			if (find[a] == theparent) {
				found = true;
			}
		}

		if (found) {
			return theparent;
		} else {
			return this.parentNode.closest(selector);
		}
	} else {
		return 0;
	}
}

Element.prototype.getPositionTop = function () {
    var top = 0;
    var elm = this;

    if (elm.offsetParent) {
        do {
            top += elm.offsetTop;
            elm = elm.offsetParent;
        } while (elm);

        return top >= 0 ? top : 0;
    }
}

Array.prototype.removeValue = function(removeValue) {
	var newArray = [];

	for (var a = 0; a < this.length; a++) {
		if (this[a] != removeValue) {
			newArray.push(this[a]);
		}
	}
	
	return newArray;
}

String.prototype.startsWith = function (str) {
    return this.indexOf(str) == 0;
}


/* -------------------------------------------------- Misc functions */
function getAnimationPrefix() {
    var element = document.createElement('div'),
	animations = {
	    'animation': 'animationend',
	    'webkitAnimation': 'webkitAnimationEnd',
	    'msAnimation': 'msAnimationEnd',
	    'mozAnimation': 'MozanimationEnd'
	};

    for (var animation in animations) {
        if (element.style[animation] !== undefined) {
            return animations[animation];
        }
    }
}

(function () {
    var lastTime = 0;
    var vendors = ['ms', 'moz', 'webkit', 'o'];

    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function (callback, element) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(
				function () { callback(currTime + timeToCall); },
				timeToCall
			);
            lastTime = currTime + timeToCall;
            return id;
        };
    }

    if (!window.cancelAnimationFrame) {
        window.cancelAnimationFrame = function (id) {
            clearTimeout(id);
        };
    }
}());

function scrollTo(element, to, duration) {
    var start = element.scrollTop,
        change = to - start,
        increment = 20;

    var animateScroll = function (elapsedTime) {
        elapsedTime += increment;
        var position = easeInOut(elapsedTime, start, change, duration);
        element.scrollTop = position;

        if (elapsedTime < duration) {
            setTimeout(function () {
                animateScroll(elapsedTime);
            }, increment);
        }
    };

    animateScroll(0);
}

function easeInOut(currentTime, start, change, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) {
        return change / 2 * currentTime * currentTime + start;
    }
    currentTime -= 1;
    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
}

function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }

    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
	results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}



/* -------------------------------------------------- Check ready state */
var checkReadyState = setInterval(function () {
    if (document.readyState == 'complete' || document.readyState == 'loaded' || document.readyState == 'interactive') {
        clearInterval(checkReadyState);
        init();
    }
}, 100);


function init() {
    
    /* -------------------------------------------------- Page load */
    setTimeout(function () {
        document.body.removeClass('preload');
    }, 250);
    

    /* -------------------------------------------------- Init Rellax */
    if (Modernizr.mq('(min-width: 1024px)') && document.querySelectorAll('.rellax').length > 0) {
        var rellax = new Rellax('.rellax');

        window.addEventListener('resize', function (e) {
            if (Modernizr.mq('(max-width: 1023px)')) {
                rellax.destroy();
            }
        });
    }


    /* -------------------------------------------------- Init WOW */

    if (Modernizr.mq('(min-width: 1366px)')) {
        var wow = new WOW({
            offset: 300
        });
        wow.init();
    }
    else {
        var wow = new WOW({
            offset: 150
        });
        wow.init();
    }



    /* ---------------------------------------------------------------------------------------------------- *\
	    OBJECT FIT POLYFILL
	\* ---------------------------------------------------------------------------------------------------- */

    objectFitImages();




    /* ---------------------------------------------------------------------------------------------------- *\
        SITE NAVIGATION
    \* ---------------------------------------------------------------------------------------------------- */

    (function () {
        var scrollPositionPrev = 0,
            lastShowScrollPosition = 0,
            siteNavigationWrap = document.getElementById('site-navigation-wrap'),
            siteNavigationTrigger = document.getElementById('site-navigation-trigger'),
            siteNavigationTriggerThirdLine = siteNavigationTrigger.querySelector('.third');

        // Page scroll
        var pageScroll = function () {
            var scrollPosition = window.pageYOffset;

            if (document.body.hasClass('js-site-navigation-open')) {
                document.body.removeClass('js-site-navigation-open')
            }

            if (scrollPosition > scrollPositionPrev && scrollPosition > (siteNavigationWrap.offsetTop + siteNavigationTrigger.offsetHeight)) {
                document.body.removeClass('js-site-navigation-open');
                siteNavigationTrigger.removeClass('js-show');
                siteNavigationTrigger.addClass('js-hide');                
            } else {
                siteNavigationTrigger.removeClass('js-hide');
                siteNavigationTrigger.addClass('js-show');

                lastShowScrollPosition = scrollPosition;
            }

            scrollPositionPrev = scrollPosition;
        };
        window.addEventListener('scroll', pageScroll);
        pageScroll();


        /* -------------------------------------------------- Check ready state */
        siteNavigationTrigger.addEventListener('click', function () {
            siteNavigationTrigger.removeClass('js-show');

            if (document.body.hasClass('js-site-navigation-open')) {
                document.body.removeClass('js-site-navigation-open')
            } else {
                document.body.addClass('js-site-navigation-open')
            }
        });
    })();




    /* ---------------------------------------------------------------------------------------------------- *\
        ANCHOR SCROLL LINKS
    \* ---------------------------------------------------------------------------------------------------- */

    (function () {
        var anchorScrollLinks = document.querySelectorAll('*[data-anchor-link]');

        Array.prototype.forEach.call(anchorScrollLinks, function (linkElement, linkIndex) {
            var scrollToElement = document.getElementById(linkElement.getAttribute('data-anchor-link'));

            if (scrollToElement != undefined) {
                linkElement.addEventListener('click', function (e) {
                    e.preventDefault();

                    scrollTo(document.documentElement, scrollToElement.offsetTop, 1000);
                    scrollTo(document.body, scrollToElement.offsetTop, 1000);
                });
            }
        });
    })();
}
